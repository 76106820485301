.carousel-wrapper .carousel .control-dots {
    bottom: -5px; /* Adjust this to move dots outside the content */
}

.carousel-wrapper .carousel .carousel-status {
    top: -50px; /* Adjust this to move the page count above the content */
    right: 10px;
    color: #fff; /* Customize color if needed */
}

/* Additional custom styles */
.carousel-wrapper .event-card img {
    max-height: 300px; /* Limit the image height */
    object-fit: cover; /* Ensure the image covers the area */
}

.carousel-wrapper .carousel .slider-wrapper {
   /* padding-bottom: 10px; /* Add padding to prevent overlap with content */
}
